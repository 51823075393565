import React, { memo } from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'
import { IoCloseCircleOutline } from "react-icons/io5"
import Modal from '../Modal'
import welcomeLogo from 'assets/welcome-logo.svg'
import welcomeBkg from 'assets/welcome-bg.png'
import styles from './styles.module.sass'
import { useEnvVersion } from 'hooks/useEnvVersion'

/**
 * Modal de boas-vindas que é exibido quando há uma atualização de versão
 * @component
 * @param {Object} props - Propriedades do componente
 * @returns {React.ReactElement} Componente Modal de boas-vindas
 */
const WelcomeModal = memo(() => {
   const [modalOpen, handleToggle] = useEnvVersion()

   return (
      <Modal
         isOpen={modalOpen}
         toggle={handleToggle}
         centered
         size="lg"
         className={styles.modal}
         backdropClassName={styles.backdrop}
         data-testid="welcome-modal"
      >
         <ModalHeader
            className={styles['modal-header']}
            close={
               <IoCloseCircleOutline
                  size={24}
                  onClick={handleToggle}
                  aria-label="Close modal"
               />
            }
         />
         <ModalBody className={styles['modal-body']}>
            <img src={welcomeLogo} alt="Car BigData Logo" />
            <h2 className={styles['modal-title']}>
               Nossa nova marca está no ar!
            </h2>
            <div className={styles['modal-content']}>
               <p>
                  Agora, o design da plataforma está atualizado! Essa mudança reflete
                  o nosso crescimento e evolução.{" "}
                  <strong>Aproveite o novo visual!</strong>
               </p>
            </div>
         </ModalBody>
         <img
            src={welcomeBkg}
            alt="Background decoration"
            className={styles['modal-bkg']}
         />
      </Modal>
   )
})

WelcomeModal.displayName = 'WelcomeModal'

export default WelcomeModal